import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 17일부터 롯데하이마트 자급제폰 구매 시, 핀다이렉트샵 알뜰폰 후불유심4종 특전 ",
  "| 온-오프라인, 이종(異種)산업 경계 허물고 소비자 접점 강화",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/18/vNFCJmTw.jpeg" alt="Figure1" />
      </div>
    </div>
    <p>
      <br />
      “물 들어올 때 노 저어 고객에게 갑니다!”
      <br />
      <br />
      스테이지파이브(대표 서상원)가 알뜰폰 가입자 천만 시대를 맞아 온-오프라인 및 이종(異種)산업 간 경계를 허물고 소비자에게
      한걸음 더 다가간다.
      <br />
      <br />
      스테이지파이브는 17일(화)부터 핀다이렉트 LTE 알뜰폰 후불 유심 4종을 롯데하이마트 자급제 스마트폰 구매 고객에 한정하여 특별
      요금제 혜택을 제공 중이다.
      <br />
      <br />
      요금제 안내는 롯데하이마트 온라인쇼핑몰 및 전국 오프라인 매장에서 QR코드 및 개별 알림 메시지를 통하여 진행한다. 점차
      늘어나고 있는 자급제폰 구매고객들을 위해 보다 편의성을 높이고 소비자 선택폭을 넓히기 위한 전략이다.
      <br />
      <br />
      판매되는 알뜰폰 후불 유심 요금제는 △’완전자유 뭐이런걸다 11GB+(뮤직)’ (11GB+150GB 24개월 추가제공+일2GB+3Mbps) △’완전자유
      뭐이런걸다 11GB+(기프티쇼)’ (11GB+150GB 24개월 추가제공+일2GB+3Mbps,) △’완전자유 뭐이런걸다 11GB+(N쇼핑)’ (11GB+150GB 24개월
      추가제공+일2GB+3Mbps) △’완전자유 실속 1GB+’ (1GB+해지시까지 6GB 추가제공+1Mbps) 이다.
      <br />
      <br />
      다양한 요금제에 더하여 12개월 간 요금 할인 혜택 및 5,000원 상당 NFC탑재 유심의 구매 비용이 면제된다. 요금 할인은 월
      5,000원~10,000원 수준이다. 월 19,000원~ 37,000원의 통신요금이 월 14,000원~27,000원까지 내려가게 돼 합리적인 통신 요금을
      원하는 고객에게 안성맞춤이다.
      <br />
      <br />
      뿐만 아니라 제휴 요금제에 따라 △지니뮤직 스트리밍 포인트(8,000포인트, 해지 시까지) △기프티쇼 쿠폰(5천원상당, 24개월간)
      △N-pay 쿠폰(5천원상당, 24개월간) 혜택을 누릴 수 있어 알뜰폰 유저에게 ‘소확행’을 선사한다.
      <br />
      <br />
      | 다양한 제휴 요금제 출시에 이어 판로 확대까지…”대문 나서 고객에게 적극 다가갈 것’
      <br />
      <br />
      스테이지파이브는 카카오톡 등 온라인 비대면 통신 가입이 가능한 플랫폼 ‘핀다이렉트샵’을 최강점으로 내세우는
      알뜰통신사업자이다. 그러나 국내 가전제품 유통 전문점 롯데하이마트(대표 황영근)와 제휴에 나서, 오프라인 유통 파트너와는 처음
      발을 맞춘다. 과감하게 이종산업 사업자와 손잡고 온-오프라인 경계를 허문 셈이다. 양사는 소비자 접점을 강화함으로써 알뜰폰 사업
      활성화를 도모한다는 계획이다.
      <br />
      <br />
      스테이지파이브 관계자는 “지난 3월 자사 알뜰폰 유심 요금제 출시 이후 다양한 제휴 요금제를 출시 중이며, 알뜰폰 후불 요금제
      후발주자임에도 이용자들께 뜨거운 관심을 받고 있다”면서 ”알뜰폰을 선택해주시는 고객들이 점차 늘고 있는 만큼, 합리적인 소비에
      대한 니즈가 큰 것으로 분석된다. ‘물 들어올 때 노 저어’ 고객에게 더욱 적극적으로 다가가는 한편, 앞으로도 상식을 뛰어넘는 제휴
      및 사업을 이어가며 고객 사랑에 보답하겠다”고 포부를 전했다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press15 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 8월 19일 즉시 배포 가능"
      pageInfo="*총 2매/ 첨부: 사진 1매"
      title="핀다이렉트샵도 고객께 성큼,<br/>“롯데하이마트에서 자급제폰도 사고, 알뜰폰 유심할인도 받고!”"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press15
